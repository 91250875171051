import './App.css';
import {Card, Image, Carousel, Flex} from 'antd';


/* 2000 Ford Mustang Car */
import mustang1 from "./assets/cars/fm1.jpg";
import mustang2 from "./assets/cars/fm2.jpg";
import mustang3 from "./assets/cars/fm3.jpg";
import mustang4 from "./assets/cars/fm4.jpg";

function App() {
  return (
    <div>
        <div className="App">
          <div className="hero-image">
            <header className="App-header-2">
              <br/>
              BBA Auto Sales<br/>
              610 N. Cunningham Urbana, IL. 61802 <br/>
            </header>
          </div>
        </div>
        <div className='separator'>Contact Us at 217-344-2020</div>
        <div> 
        <Flex wrap justify='space-evenly' align='center'>
        <Card title="2000 Ford Mustang - $15,000" variant="borderless" style={{ width: 500 }}>
            <p>95K Miles, V8 Manual, New oil change, new brakes, new exhaust, excellent condition.</p> 
            <Carousel arrows infinite={true}>
                <div>
                <Image width={500} src= {mustang1}/>                 
                </div>
                <div>
                <Image width={500} src= {mustang2}/>                 
                </div>
                <div>
                <Image width={500} src= {mustang3}/>                 
                </div>
                <div>
                <Image width={500} src= {mustang4}/>                 
                </div>
            </Carousel>
          </Card>
        </Flex>
        </div>
        <div>
          <div className='separator'>About Us</div>
          <Flex justify='space-evenly' align='center' vertical>
            <p className='App-info-test' style={{ maxWidth: 800 }}>BBA Auto Sales is family owned and operated. We have been part of the Urbana community for the last 30+ years. Being a small family owned dealership allows us to provide quality cars while keeping our prices low.</p>
            <p className='App-info-test' style={{ maxWidth: 800 }}>Antonio Barreda - Owner</p>
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3051.0649338166468!2d-88.20565628435764!3d40.11855638164319!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880cd79c8a9ba8bb%3A0xbe55a6bc792bdab1!2sBBA%20Auto%20Sales%20LLC!5e0!3m2!1sen!2sus!4v1578122642967!5m2!1sen!2sus"
              width="390px"
              height="350px"
              display="initial"
              title="YouTube Video"
          />
          </Flex>
        </div>
        <div className='separator' style={{ height: 100 }}>Copyright @ 2025 BBA Auto Sales</div>
    </div>
  );
}

export default App;
